import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Row, Col, Modal, Button, Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used by the library
import CouponCodeModal from "./CouponCodeModal";
import { formatToAUD } from "../utils";
import AssignCouponCodeModal from "./AssignCouponCodeModal";
import ReferralModal from "./ReferralModal";

export default function Home(props) {

  const [userAccess, setUserAccess] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);  
  const [couponModal, setCouponModal] = useState(false);
  const [assignCouponModal, setAssignCouponModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [download, setDownload] = useState(false);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const searchRef = useRef(null);
  const currentTenant = useRef({});
  const lucaPayUsers = useRef({});
  const tenantCreditLimit = useRef(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    tenantCreditLimit.current = 0;
    setShow(true)
  };

  const editCreditLimit = (tenantId, userStats) => {
    currentTenant.current = {
      tenantId,
      userStats
    }
    handleShow();
  }

  const shorten = (text) => {
    const limit = 25;
    if (text.length > 25) text = text.slice(0, limit / 2 - 3) + "[...]" + text.slice(-limit / 2 - 1);
    return text;
  }

  const removeAdminCreditLimit = (tenantId, tenantName) => {
    currentTenant.current = {
      tenantId,
      tenantName
    }
    handleConfirm();
  }

  const handleConfirm = () => {
    setConfirm(true);
  }

  const closeConfirm = () => {
    setConfirm(false);
  }

  const handleRemoveCreditLimit = () => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_LUCA_APP}/api/v1/lucapay/admin/clearCreditLimit`,
      method: "post",
      headers: {
        Authorization: `bearer ${props.user.accessToken}`
      },
      data: {
        tenantId: currentTenant.current.tenantId,
      }
    }).then(data => {
      setLoading(false);
      setRefresh(true);
      closeConfirm();
    }).catch(err => {
      setLoading(false);
      alert("Error setting credit limit!\n" + err.message);
    });
  };

  const setNewCreditLimit = (event) => {
    tenantCreditLimit.current = event.target.value;
  };


  const handleUpdateCreditLimit = () => {
    if (tenantCreditLimit.current > 0) {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_LUCA_APP}/api/v1/lucapay/admin/tenants`,
        method: "post",
        headers: {
          Authorization: `bearer ${props.user.accessToken}`
        },
        data: {
          tenantId: currentTenant.current,
          creditLimit: tenantCreditLimit.current
        }
      }).then(data => {
        setLoading(false);
        setRefresh(true);
        handleClose();
      }).catch(err => {
        setLoading(false);
        alert("Error setting credit limit!\n" + err.message);
      });
    }
  }

  const assignCouponCode = (tenantId, code) => {
    axios({
      url: `${process.env.REACT_APP_LUCA_APP}/api/v1/admin/addPromoCodeToTenant`,
      method: "patch",
      headers: {
        Authorization: `bearer ${props.user.accessToken}`
      },
      data: {
        promoCode: code,
        tenantId
      }
    }).then(data => {
      alert("Coupon code added successfully!");
    }).catch(err => {
      setLoading(false);
      const errorMessage = err?.response?.data?.error?.message || err?.message;
      alert("Error adding coupon code!\n" + errorMessage  );
    });
  }

  const getReferrals = () => {

    const tenantId = currentTenant?.current?.id;
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_LUCA_APP}/api/v1/admin/referral?tenantId=${tenantId}`,
      method: "get",
      headers: {
        Authorization: `bearer ${props.user.accessToken}`
      },
    }).then(data => {
      currentTenant.current.referrals = data.data;
      setReferralModal(true);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      alert("Error getting referrals!\n" + err.message);
    });
  }

  useEffect(() => {
    if (props.user) {
      setUserAccess(true);
    } else setUserAccess(false);

  }, [props.user]);


  useEffect(() => {
    if (refresh) {
      console.log("getting users");
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_LUCA_APP}/api/v1/lucapay/admin/tenants`,
        headers: {
          Authorization: `bearer ${props.user.accessToken}`
        }
      }).then(data => {
        setLoading(false);
        const response = data.data;
        lucaPayUsers.current = { lucaPayTenants: response.data.lucaPayTenants, tenantLimits: response.data.tenantLimits };
        setRefresh(false);

      }).catch(err => setLoading(false));
    }
  }, [props.user.accessToken, refresh]);


  useEffect(() => {
    if (search) {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_LUCA_APP}/api/v1/lucapay/admin/tenants?search=${search}`,
        headers: {
          Authorization: `bearer ${props.user.accessToken}`
        }
      }).then(data => {
        setLoading(false);
        const response = data.data;
        lucaPayUsers.current = { lucaPayTenants: response.data.lucaPayTenants, tenantLimits: response.data.tenantLimits };
        setRefresh(false);

      }).catch(err => setLoading(false));
    }
  }, [search]);

  useEffect(() => {

    if (!!download && !loading) {
      if (lucaPayUsers.current.lucaPayTenants) {
        const searchWord = (search) ? `?search=${search}` : '';
        setDownloadExcel(true)
        axios({
          url: `${process.env.REACT_APP_LUCA_APP}/api/v1/lucapay/admin/downloadTenantData/user${searchWord}`,
          headers: {
            Authorization: `bearer ${props.user.accessToken}`
          },
          responseType: 'blob'
        }).then(response => {
          setLoading(false);
          setDownloadExcel(false);
          const url = URL.createObjectURL(new Blob([response?.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `LPData.xlsx`); 
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          setLoading(false);
          setDownloadExcel(false);
          if(err.response.status === 404){            
            alert("You got an error. Please contact your development team")
          }else{
            alert(err.message);
          }         
        });
      }
      setDownload(false);
    } else if (!!download && loading) {
      alert("Please wait for the data to load ...");
    }

  }, [download, loading]);

  const decodeHTML = (encodedText) => {

    if (!encodedText) return "";

    const parser = new DOMParser();
    const context = parser.parseFromString(encodedText, 'text/html');

    return context.body.textContent;
  };

  const getABN = (lucaPayUser) => {
    if (!lucaPayUser.abnInfo) return "";
    const oldABN = lucaPayUser.abnInfo.abn?.replaceAll(" ", "");
    const newABN = lucaPayUser.accounts[0].details?.abn?.replaceAll(" ", "") || "";

    if (!newABN) return <>{oldABN} <br /> (ABN Missing from org config) </>;
    if (oldABN === newABN) return oldABN;
    if (oldABN !== newABN) return <>{oldABN} <br /> (ABN mismatch ABN in org config - {newABN} </>;
  }
  const downLoadPopUp = (message ) => {
    return (
      <div className="download_Popup_Container">
        <div className="download_Popup">
          {message}
        </div>
      </div>
    );
  }

  return <Container fluid>
    <div className="d-flex justify-content-end mx-5 my-5">

      {downloadExcel ? downLoadPopUp('Downloading ...'):<FontAwesomeIcon icon={solid('file-excel')} size="2x" className="button-icon" onClick={(evt) => {
          evt.preventDefault();
          setDownload(true);
        }} title="Download User Summary" />
      }

      <input ref={searchRef} type="text" />
      <FontAwesomeIcon icon={solid('magnifying-glass')} size="2x" className="button-icon" onClick={(evt) => {
        evt.preventDefault();
        setSearch(searchRef.current.value);
      }
      } />

      <FontAwesomeIcon icon={solid('delete-left')} size="2x" className="button-icon" onClick={(evt) => {
        evt.preventDefault();
        if (!searchRef.current.value) return;
        searchRef.current.value = "";
        setRefresh(true);
      }
      } title="Clear Search" /></div>
    {
      userAccess ?
        <>
          {loading ? <div>Loading ... </div> : " "}

          <Table responsive="xl">
            <thead>
              <tr>
                <th>Tenant</th>
                <th></th>
                <th>Sources</th>
                <th>LPay</th>
                <th>Software</th>
                <th>Attachments</th>
                <th>KYC</th>
                <th>Limits</th>
                <th>Balances</th>
              </tr>
            </thead>
            <tbody>

              {lucaPayUsers.current.lucaPayTenants && lucaPayUsers.current.lucaPayTenants.map(lucaPayUser => {

                const userStats = lucaPayUsers.current.tenantLimits[lucaPayUser.tenantId];
                const lpSources = (lucaPayUser.lucaPaySources && lucaPayUser.lucaPaySources.filter(src => src.active && src.details.active)
                  .map(src => {
                    const type = src.details.paymentMethodName;

                    if (!src.details.rawPaymentMethod) return "";

                    const rawDetails = typeof src.details.rawPaymentMethod === "object" ? src.details.rawPaymentMethod : JSON.parse(src.details.rawPaymentMethod);

                    try {
                      if (type === "card") return `CC ${rawDetails.card.brand}-${rawDetails.card.last4} EXP ${rawDetails.card.exp_month}/${rawDetails.card.exp_year} ${rawDetails.card.country}`;
                      if (type === "au_becs_debit") return `DD XXXX-${rawDetails.au_becs_debit.last4} BSB ${rawDetails.au_becs_debit.bsb_number}`;
                    } catch (err) { return ""; }
                    return "";
                  }).filter(src => src !== "")) || [];

                const accountDetail = lucaPayUser.accounts.length > 0 && lucaPayUser.accounts[0];

                return <tr key={lucaPayUser._id} className="min-vw-100">
                  <td>
                    {(accountDetail.disabled || accountDetail.forceReconnect) && <FontAwesomeIcon icon={solid("circle-exclamation")}
                      beat style={{ color: "#ce2e1c", }} className="me-2" title="Disconnected user" />}

                    ABN {getABN(lucaPayUser)} <br />
                    {lucaPayUser.abnInfo ? decodeHTML(lucaPayUser.abnInfo.legalName) : ""} <br />
                    {accountDetail.invoiceEmail && accountDetail.invoiceEmail.map(em => {
                      return <> {em} <br /></>
                    })} <br />


                    <i>{accountDetail.details && accountDetail.details.industry}</i>
                    {accountDetail.notes ? <><br /><i>{accountDetail.notes}</i></> : ""}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={solid("user-group")} className="me-2" title="Referrals" onClick={(evt) => {
                      evt.preventDefault();
                      currentTenant.current = {
                        id: lucaPayUser.tenantId,
                        name: decodeHTML(lucaPayUser.abnInfo.legalName)
                      };                      
                      getReferrals();
                    }} /> <br/>
                    <FontAwesomeIcon icon={solid("ticket")} className="me-2" title="Assign Promo Code" onClick={(evt) => {
                      evt.preventDefault();
                      currentTenant.current = {
                        id: lucaPayUser.tenantId,
                        name: decodeHTML(lucaPayUser.abnInfo.legalName)
                      };
                      setAssignCouponModal(true);
                    }} />
                  </td>
                  <td>
                    {lpSources.map(src => {
                      return <>{src}<br /></>;
                    })}
                  </td>
                  <td>
                    {lucaPayUser.disabled ? "Inactive" : "active"}
                  </td>
                  <td>
                    {lucaPayUser.accounts.length ? lucaPayUser.accounts[0].type : "Disabled"}
                  </td>
                  <td>
                    {((lucaPayUser.accounts.length > 0 && lucaPayUser.accounts[0].attachments && (lucaPayUser.accounts[0].attachments.length > 0 && lucaPayUser.accounts[0].attachments.map(attachment => {

                      return <><a href={attachment.url}>{shorten(attachment.name)}</a><br /></>
                    }))) || "")}
                  </td>
                  <td>
                    {lucaPayUser.payoutInfo && lucaPayUser.payoutInfo.status === "complete" ? "KYC complete" : "In progress"}
                  </td>
                  <td>
                    {lucaPayUser.accounts.length ? <>
                      {userStats && userStats.riskCreditLimit > 0 ? <> Dashboard: {formatToAUD(userStats.riskCreditLimit)} <br /> </> : ""}
                      Calculated: {userStats && formatToAUD(userStats.systemSetCreditLimit)} <br /> <br />
                      Limit (Defaults): {userStats && formatToAUD(userStats.effectiveRemainingCredit)} <br />
                      <FontAwesomeIcon icon={solid('pen-to-square')} size="1x" onClick={() => editCreditLimit(lucaPayUser.tenantId, userStats)} /> Remaining: {userStats && formatToAUD(userStats.remainingCredit)} <br />
                      <FontAwesomeIcon icon={solid('trash')} size="1x" onClick={() => removeAdminCreditLimit(lucaPayUser.tenantId, decodeHTML(lucaPayUser.abnInfo.legalName))} /> Admin: {userStats && (formatToAUD(userStats.adminSetCreditLimit || 0))}
                    </>
                      : ""
                    }

                  </td>
                  <td>
                    Reconciled: {(userStats && formatToAUD(userStats.reconciledIncome)) || 0.00} <br />
                    Projected: {(userStats && formatToAUD(userStats.projectedIncome)) || 0.00} <br />
                    bankBalance: N/A
                  </td>
                </tr>
              })
              }
            </tbody>
          </Table>

        </> : "Request access"
    }


    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Credit Limit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-end px-4">
          <Col>Calculated</Col>
          <Col>{currentTenant.current.userStats && formatToAUD(currentTenant.current.userStats.systemSetCreditLimit)}</Col>
        </Row>
        <Row className="justify-content-end px-4">
          <Col>Previous Limit</Col>
          <Col>{currentTenant.current.userStats && formatToAUD(currentTenant.current.userStats.adminSetCreditLimit || 0)}</Col>
        </Row>

        <Row className="align-items-center px-4 py-2">
          <Col>New Limit</Col>
          <Col><Form.Control type="number" placeholder="Credit limit" id="creditLimit" onChange={setNewCreditLimit} /></Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdateCreditLimit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={confirm} onHide={closeConfirm}>
      <Modal.Header closeButton>
        <Modal.Title>Remove Admin Credit Limit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-end px-4">
          <Col>Tenant</Col>
          <Col>{currentTenant.current.tenantName}</Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeConfirm}>
          Close
        </Button>
        <Button variant="danger" onClick={handleRemoveCreditLimit}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>

    <AssignCouponCodeModal showCouponModal={assignCouponModal} closeCouponModal={() => setAssignCouponModal(false)} user={props.user} tenant={currentTenant} assignCouponCode={assignCouponCode} />
    <ReferralModal showReferralModal={referralModal} closeReferralModal={() => setReferralModal(false)} user={props.user} tenant={currentTenant} referrals={getReferrals} />

  </Container>;
}